import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Moore%27s_law"
      }}>{`Moore's Law`}</a>{` states that the
number of transistors on a chip roughly doubles every two years. But how does
that stack up against reality?`}</p>
    <p>{`I was inspired by this
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=7uvUiq_jTLM"
      }}>{`data visualization of Moore's law`}</a>{`
from @datagrapha going viral on Twitter and decided to replicate it in React
and D3.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactfordataviz.com/articles/moores-law/"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/W3OVpFL.gif",
          "alt": null
        }}></img></a></p>
    <p>{`Some data bugs break it down in the end and there's something funky with Voodoo
Rush, but those transitions came out wonderful 👌`}</p>
    <p>{`You can watch me build it from scratch, here 👇`}</p>
    <lite-youtube {...{
      "videoid": "m34_D8Va-F4",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`First 30min eaten by a technical glitch 🤷‍♀️`}</p>
    <p>{`Try it live in your browser, here 👉
`}<a parentName="p" {...{
        "href": "https://moores-law-swizec.swizec-react-dataviz.now.sh"
      }}>{`https://moores-law-swizec.swizec-react-dataviz.now.sh`}</a></p>
    <p>{`And here's the
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/moores-law"
      }}>{`full source code on GitHub`}</a>{`.`}</p>
    <p><em parentName="p">{`you can `}<a parentName="em" {...{
          "href": "https://reactfordataviz.com/articles/moores-law/"
        }}>{`Read this online`}</a></em></p>
    <h1 {...{
      "id": "how-it-works"
    }}>{`How it works`}</h1>
    <p>{`At its core Moore's Law in React & D3 is a bar chart flipped on its side.`}</p>
    <p>{`We started with fake data and a React component that renders a bar chart. Then
we made the data go through time and looped through. The bar chart jumped
around.`}</p>
    <p>{`So our next step was to add transitions. Made the bar chart look smooth.`}</p>
    <p>{`Then we made our data gain an entry each year and created an enter transition
to each bar. Makes it smoother to see how new entries fly in.`}</p>
    <p>{`At this point we had the building blocks and it was time to use real data. We
used `}<a parentName="p" {...{
        "href": "https://wikitable2csv.ggor.de/"
      }}>{`wikitable2csv`}</a>{` to download data from
Wikipedia's `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Transistor_count"
      }}>{`Transistor Count`}</a>{`
page and fed it into our dataviz.`}</p>
    <p>{`Pretty much everything worked right away 💪`}</p>
    <h2 {...{
      "id": "start-with-fake-data"
    }}>{`Start with fake data`}</h2>
    <p>{`Data visualization projects are best started with fake date. This approach lets
you focus on the visualization itself. Build the components, the transitions,
make it all fit together ... all without worrying about the exact shape of your
data.`}</p>
    <p>{`Of course it's best if your fake data looks like your final dataset will.
Array, object, grouped by year, that sort of thing.`}</p>
    <p>{`Plus you save time when you aren't waiting for large datasets to parse :)`}</p>
    <p>{`Here's the fake data generator we used:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

const useData = () => {
  const [data, setData] = useState(null);

  // Replace this with actual data loading
  useEffect(() => {
    // Create 5 imaginary processors
    const processors = d3.range(10).map(i => \`CPU \${i}\`),
      random = d3.randomUniform(1000, 50000);

    let N = 1;

    // create random transistor counts for each year
    const data = d3.range(1970, 2026).map(year => {
      if (year % 5 === 0 && N < 10) {
        N += 1;
      }

      return d3.range(N).map(i => ({
        year: year,
        name: processors[i],
        transistors: Math.round(random()),
      }));
    });

    setData(data);
  }, []);

  return data;
};
`}</code></pre>
    <p>{`Create 5 imaginary processors, iterate over the years, and give them random
transistor counts. Every 5 years we increase the total `}<inlineCode parentName="p">{`N`}</inlineCode>{` of processors in our
visualization.`}</p>
    <p>{`We create data inside a `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` to simulate that data loads asynchronously.`}</p>
    <h2 {...{
      "id": "driving-animation-through-the-years"
    }}>{`Driving animation through the years`}</h2>
    <p>{`A large part of visualizing Moore's Law is showing its progression over the
years. Transistor counts increased as new CPUs and GPUs entered the market.`}</p>
    <p>{`Best way to drive that progress animation is with a `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` and a D3 timer.
We do that in our `}<inlineCode parentName="p">{`App`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

function App() {
    const data = useData();
    const [currentYear, setCurrentYear] = useState(1970);

    const yearIndex = d3
        .scaleOrdinal()
        .domain(d3.range(1970, 2025))
        .range(d3.range(0, 2025 - 1970));

    // Drives the main animation progressing through the years
    // It's actually a simple counter :P
    useEffect(() => {
        const interval = d3.interval(() => {
            setCurrentYear(year => {
                if (year + 1 > 2025) {
                    interval.stop();
                }

                return year + 1;
            });
        }, 2000);

        return () => interval.stop();
    }, []);
`}</code></pre>
    <p><inlineCode parentName="p">{`useData()`}</inlineCode>{` runs our data generation custom hook. We `}<inlineCode parentName="p">{`useState`}</inlineCode>{` for the current
year. A linear scale helps us translate from meaningful `}<inlineCode parentName="p">{`1970`}</inlineCode>{` to `}<inlineCode parentName="p">{`2026`}</inlineCode>{`
numbers to indexes in our data array.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` starts a `}<inlineCode parentName="p">{`d3.interval`}</inlineCode>{`, which is like a `}<inlineCode parentName="p">{`setInterval`}</inlineCode>{` but more
reliable. We update current year state in the interval callback.`}</p>
    <p>{`Remember that state setters accept a function that gets current state as an
argument. Useful trick in this case where we don't want to restart the effect
on every year change.`}</p>
    <p>{`We return `}<inlineCode parentName="p">{`interval.stop()`}</inlineCode>{` as our cleanup function so React stops the loop
when our component unmounts.`}</p>
    <h2 {...{
      "id": "the-basic-render"
    }}>{`The basic render`}</h2>
    <p>{`Our main component renders a `}<inlineCode parentName="p">{`<Barchart>`}</inlineCode>{` inside an `}<inlineCode parentName="p">{`<Svg>`}</inlineCode>{`. Using styled
components for size and some layout.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

return (
    <Svg>
        <Title x={"50%"} y={30}>
            Moore's law vs. actual transistor count in React & D3
        </Title>
        {data ? (
            <Barchart
                data={data[yearIndex(currentYear)]}
                x={100}
                y={50}
                barThickness={20}
                width={500}
            />
        ) : null}
        <Year x={"95%"} y={"95%"}>
            {currentYear}
        </Year>
    </Svg>
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`Svg`}</inlineCode>{` is styled to take up the entire viewport and the `}<inlineCode parentName="p">{`Year`}</inlineCode>{` component is
a big text.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`<Barchart>`}</inlineCode>{` is where our dataviz work happens. From the outside it's a
component that takes "current data" and handles the rest. Positioning and
sizing props make it more reusable.`}</p>
    <h2 {...{
      "id": "a-smoothly-transitioning-barchart"
    }}>{`A smoothly transitioning Barchart`}</h2>
    <p><a parentName="p" {...{
        "href": "https://reactfordataviz.com/articles/moores-law/"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/W3OVpFL.gif",
          "alt": null
        }}></img></a></p>
    <p>{`Our goal with the Barchart component was to:`}</p>
    <ul>
      <li parentName="ul">{`always render current state`}</li>
      <li parentName="ul">{`have smooth transitions on changes`}</li>
      <li parentName="ul">{`follow React-y principles`}</li>
      <li parentName="ul">{`easy to use from the outside`}</li>
    </ul>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=m34_D8Va-F4"
      }}>{`watch the video`}</a>{` to see
how it evolved. Here I explain the final state 😇`}</p>
    <h3 {...{
      "id": "the-barchart-component"
    }}>{`The `}<inlineCode parentName="h3">{`<Barchart>`}</inlineCode>{` component`}</h3>
    <p>{`The Barchart component takes in data, sets up vertical and horizontal D3
scales, and loops through data to render individual bars.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/Barchart.js

// Draws the barchart for a single year
const Barchart = ({ data, x, y, barThickness, width }) => {
    const yScale = useMemo(
        () =>
            d3
                .scaleBand()
                .domain(d3.range(0, data.length))
                .paddingInner(0.2)
                .range([data.length * barThickness, 0]),
        [data.length, barThickness]
    );

    // not worth memoizing because data changes every time
    const xScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, d => d.transistors)])
        .range([0, width]);

    const formatter = xScale.tickFormat();
`}</code></pre>
    <p>{`D3 scales help us translate between datapoints and pixels on a screen. I like
to memoize them when it makes sense.`}</p>
    <p>{`Memoizing is particularly important with large datasets. You don't want to
waste time looking for the max in 100,000 elements on every render.`}</p>
    <p>{`We were able to memoize `}<inlineCode parentName="p">{`yScale`}</inlineCode>{` because `}<inlineCode parentName="p">{`data.length`}</inlineCode>{` and `}<inlineCode parentName="p">{`barThickness`}</inlineCode>{` don't
change `}<em parentName="p">{`every`}</em>{` time.`}</p>
    <p><inlineCode parentName="p">{`xScale`}</inlineCode>{` on the other hand made no sense to memoize since we know `}<inlineCode parentName="p">{`<Barchart>`}</inlineCode>{`
gets a new data object for every render. At least in theory.`}</p>
    <p>{`We borrow xScale's tick formatter to help us render `}<inlineCode parentName="p">{`10000`}</inlineCode>{` as `}<inlineCode parentName="p">{`10,000`}</inlineCode>{`. Built
into D3 ✌️`}</p>
    <p><strong parentName="p">{`Rendering`}</strong>{` our Barchart component looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/Barchart.js

return (
  <g transform={\`translate(\${x}, \${y})\`}>
    {data
      .sort((a, b) => a.transistors - b.transistors)
      .map((d, index) => (
        <Bar
          data={d}
          key={d.name}
          y={yScale(index)}
          width={xScale(d.transistors)}
          endLabel={formatter(d.transistors)}
          thickness={yScale.bandwidth()}
        />
      ))}
  </g>
);
`}</code></pre>
    <p>{`A grouping element holds our bars together and moves them into place. Using a
group element changes the internal coordinate system so individual bars don't
have to know about overall positioning.`}</p>
    <p>{`Just like in HTML when you position a div and its children don't need to know
:)`}</p>
    <p>{`We sort data by transistor count and render a `}<inlineCode parentName="p">{`<Bar>`}</inlineCode>{` element for each.
Individual bars get all needed info via props.`}</p>
    <h3 {...{
      "id": "the-bar-component"
    }}>{`The `}<inlineCode parentName="h3">{`<Bar>`}</inlineCode>{` component`}</h3>
    <p>{`Individual `}<inlineCode parentName="p">{`<Bar>`}</inlineCode>{` components render a rectangle flanked on each side by a
label.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
  <g transform={\`translate(\${renderX}, \${renderY})\`}>
    <rect x={10} y={0} width={renderWidth} height={thickness} fill={color} />
    <Label y={thickness / 2}>{data.name}</Label>
    <EndLabel y={thickness / 2} x={renderWidth + 15}>
      {data.designer === 'Moore'
        ? formatter(Math.round(transistors))
        : formatter(data.transistors)}
    </EndLabel>
  </g>
);
`}</code></pre>
    <p>{`A grouping element groups the 3 elements, styled components style the labels,
and a `}<inlineCode parentName="p">{`rect`}</inlineCode>{` SVG element creates the rectangle. Simple React markup stuff ✌️`}</p>
    <p>{`Where the `}<inlineCode parentName="p">{`<Bar>`}</inlineCode>{` component gets interesting is the positioning. We use
`}<inlineCode parentName="p">{`renderX`}</inlineCode>{` and `}<inlineCode parentName="p">{`renderY`}</inlineCode>{` even though the vertical position comes from props as
`}<inlineCode parentName="p">{`y`}</inlineCode>{` and `}<inlineCode parentName="p">{`x`}</inlineCode>{` is static.`}</p>
    <p>{`That's got to do with transitions.`}</p>
    <h3 {...{
      "id": "transitions"
    }}>{`Transitions`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<Bar>`}</inlineCode>{` component uses the hybrid animation approach from my
`}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React For DataViz`}</a>{` course.`}</p>
    <p>{`A key insight is that we use `}<em parentName="p">{`independent`}</em>{` transitions on each axis to create a
`}<em parentName="p">{`coordinated`}</em>{` transition. Both for entering into the chart and for moving
around later.`}</p>
    <p>{`Special case for the `}<inlineCode parentName="p">{`Moore's Law`}</inlineCode>{` bar itself where we also transition the
label so it looks like it's counting.`}</p>
    <p>{`We created a `}<inlineCode parentName="p">{`useTransition`}</inlineCode>{` custom hook to make our code easier to understand
and cleaner to read.`}</p>
    <h4 {...{
      "id": "usetransition"
    }}>{`useTransition`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`useTransition`}</inlineCode>{` custom hook helps us move values from props to state. State
becomes the staging area and props are the target we want to reach.`}</p>
    <p>{`To run a transition we create an effect and set up a D3 transition. On each
tick of the animation we update state proportionately to time spent animating.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const useTransition = ({ targetValue, name, startValue, easing }) => {
  const [renderValue, setRenderValue] = useState(startValue || targetValue);

  useEffect(() => {
    d3.selection()
      .transition(name)
      .duration(2000)
      .ease(easing || d3.easeLinear)
      .tween(name, () => {
        const interpolate = d3.interpolate(renderValue, targetValue);
        return t => setRenderValue(interpolate(t));
      });
  }, [targetValue]);

  return renderValue;
};
`}</code></pre>
    <p>{`State update happens inside that custom `}<inlineCode parentName="p">{`.tween`}</inlineCode>{` method. We interpolate between
the current value and the target value.`}</p>
    <p>{`D3 handles the rest.`}</p>
    <h4 {...{
      "id": "using-usetransition"
    }}>{`Using useTransition`}</h4>
    <p>{`We can reuse that same transition approach for each independent axis we want to
animate. D3 makes sure all transitions start at the same time and run at the
same pace. Any dropped frames or browser slow downs are handled for us.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/Bar.js
const Bar = ({ data, y, width, thickness, formatter, color }) => {
    const renderWidth = useTransition({
        targetValue: width,
        name: \`width-\${data.name}\`,
        easing: data.designer === "Moore" ? d3.easeLinear : d3.easeCubicInOut
    });
    const renderY = useTransition({
        targetValue: y,
        name: \`y-\${data.name}\`,
        startValue: -500 + Math.random() * 200,
        easing: d3.easeCubicInOut
    });
    const renderX = useTransition({
        targetValue: 0,
        name: \`x-\${data.name}\`,
        startValue: 1000 + Math.random() * 200,
        easing: d3.easeCubicInOut
    });
    const transistors = useTransition({
        targetValue: data.transistors,
        name: \`trans-\${data.name}\`,
        easing: d3.easeLinear
    });
`}</code></pre>
    <p>{`Each transition returns the current value for the transitioned axis.
`}<inlineCode parentName="p">{`renderWidth`}</inlineCode>{`, `}<inlineCode parentName="p">{`renderX`}</inlineCode>{`, `}<inlineCode parentName="p">{`renderY`}</inlineCode>{`, and even `}<inlineCode parentName="p">{`transistors`}</inlineCode>{`.`}</p>
    <p>{`When a transition updates, its internal `}<inlineCode parentName="p">{`useState`}</inlineCode>{` setter runs. That triggers a
re-render and updates the value in our `}<inlineCode parentName="p">{`<Bar>`}</inlineCode>{` component, which then
re-renders.`}</p>
    <p>{`Because D3 transitions run at 60fps, we get a smooth animation ✌️`}</p>
    <p>{`Yes that's a lot of state updates for each frame of animation. At least 4 per
frame per datapoint. About 4`}{`*`}{`60`}{`*`}{`298 = 71,520 per second at max.`}</p>
    <p>{`And React can handle it all. At least on my machine, I haven't tested elsewhere
yet :)`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`And that's how you can combine React & D3 to get a smoothly transitioning
barchart visualizing Moore's Law through the years.`}</p>
    <p><a parentName="p" {...{
        "href": "https://moores-law-swizec.swizec-react-dataviz.now.sh/"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/W3OVpFL.gif",
          "alt": null
        }}></img></a></p>
    <p>{`Key takeaways:`}</p>
    <ul>
      <li parentName="ul">{`React for rendering`}</li>
      <li parentName="ul">{`D3 for data loading`}</li>
      <li parentName="ul">{`D3 runs and coordinates transitions`}</li>
      <li parentName="ul">{`state updates drive re-rendering animation`}</li>
      <li parentName="ul">{`build custom hooks for common setup`}</li>
    </ul>
    <p>{`Cheers,`}<br />{` ~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      